import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { ILocation } from "../types/Location"
import { IPageNode } from "../types/Page"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHeader from "../components/PostHeader"

import "./styles/page.scss";

interface IProps {
  location: ILocation
  data: {
    page: IPageNode
    headerImage: any
  }
}

const PageTemplate: React.FC<IProps> = ({ data, location }) => (
  <Layout currentPath={location.pathname} readingMode={true}>
    {({ theme }) => (
      <>
        <SEO title={data.page.frontmatter.title} />

        <PostHeader
          description={data.page.frontmatter.summary}
          icon={data.page.frontmatter.icon}
          imageData={getImage(data.headerImage)}
          circledImage={data.page.frontmatter.imageCircled}
          theme={theme}
          title={data.page.frontmatter.title}
        />

        <article className={`page-content--${theme}`}>
          <MDXRenderer>
            {data.page.body}
          </MDXRenderer>
        </article>
      </>
    )}
  </Layout>
)

export const query = graphql`
  query($slug: String!, $imageName: String) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        summary
        icon
        imageName
        imageCircled
      }
    }

    headerImage: file(name: { eq: $imageName }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          height: 350
          width: 350
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default PageTemplate
